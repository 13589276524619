/* ConversationDetail.css */
.conversation-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
}

.message-bubble {
    max-width: 70%;
    padding: 10px;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.message-bubble.assistant {
    align-self: flex-start;
    background-color: #e0f7fa; /* Light blue */
}

.message-bubble.user {
    align-self: flex-end;
    background-color: #c8e6c9; /* Light green */
}

.message-bubble.system {
    align-self: center;
    background-color: #eeeeee; /* Grey */
    width: 100%;
}
