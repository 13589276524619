.error-message {
    color: red;
    margin: 10px 0;
    border: 1px solid red;
    padding: 10px;
    border-radius: 5px;
}

.verification-chip {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: bold;
}

.verified {
    background-color: green;
    color: white;
}

.not-verified {
    background-color: red;
    color: white;
}

.modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.25);
    padding: 16px;
}